<script setup lang="ts">
import { Image, Source, ImageEngineProvider } from '@imageengine/vue3'
import { ref, onBeforeMount } from 'vue'

interface SourceDefinition {
  maxWidth: number
  width: number
  minWidth?: number
}

const domain = 'https://zwqblu8z.dev.cdn.imgeng.in'
const attributes = ref<Record<string, any>>()

const props = defineProps({
  url: {
    type: String,
    required: true
  },
  baseWidth: {
    type: Number,
    required: true
  },
  className: {
    type: String
  },
  imageClassName: {
    type: String
  },
  pictureClassName: {
    type: String
  },
  alt: {
    type: String
  },
  srcSets: {
    type: Array<SourceDefinition>
  },
  lazyload: {
    type: Boolean
  },
  preload: {
    type: Boolean
  },
  eager: {
    type: Boolean
  }
})

onBeforeMount(() => {
  let dynamicAttributes: Record<string, any> = {
    alt: props.alt
  }
  if (props.lazyload) {
    dynamicAttributes.loading = 'lazy'
  } else if (props.eager) {
    dynamicAttributes.loading = 'eager'
  }
  if (props.preload) {
    dynamicAttributes.rel = 'preload'
    dynamicAttributes.loading = 'eager'
    dynamicAttributes.fetchpriority = 'high'
  }
  attributes.value = dynamicAttributes
})
</script>
<template>
  <ClientOnly>
    <ImageEngineProvider :deliveryAddress="domain" :class="className">
      <picture :class="pictureClassName">
        <Source
          v-for="(set, index) in srcSets"
          :key="index"
          :srcSet="[
            {
              src: `${url}`,
              width: `${set.width}w`
            }
          ]"
          :attributes="{
            media:
              set.maxWidth > 0
                ? `(max-width: ${set.maxWidth}px)`
                : !!set.minWidth
                  ? `(min-width: ${set.minWidth}px)`
                  : ''
          }"
        />
        <Image :src="url" :width="baseWidth" :attributes="attributes" :class="imageClassName" />
      </picture>
    </ImageEngineProvider>
  </ClientOnly>
</template>
